<style>
  .el-collapse-item__header {
    font-weight: 600 !important;
  }
  .el-table .cell {
    word-break: unset;
  }
  .span-status {
    font-size: 1rem !important;
  }
  .sku-font {
    font-family: 'Roboto Mono', monospace;
  }
</style>
<template>
  <div class="row">
    <div class="row col-md-12 g-6 mt-0" v-loading="loading">
      <div class="col-md-12 fv-row" v-if="productDetails.value">
        <div class="card mb-5 mb-xl-10">
          <div class="card-header border-0 pt-6">
            <div class="flex-grow-1">
              <div class="d-flex flex-column">
                <div class="d-flex justify-content-between align-items-start flex-wrap">
                  <div class="d-flex align-items-center mb-2 float-left">
                    <span class="text-gray-900 fs-2 fw-bolder me-1">{{ productDetails.value.name }}</span>
                  </div>
                  <div class="d-flex">
                    <div class="fw-bold fs-6 text-gray-600">
                      <span v-if="productDetails.value.status == 1" class="badge badge-light-success span-status">{{ generalConstants.ACTIVE }}</span>
                      <span v-if="productDetails.value.status == 0" class="badge badge-light-danger span-status">{{ generalConstants.INACTIVE }}</span>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
                  <span class="d-flex align-items-center text-gray-400 me-5 mb-2">{{ productDetails.value.description }}</span>
                </div>
              </div>
              <div class="d-flex flex-wrap flex-stack">
                <div class="d-flex flex-wrap">
                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div class="d-flex justify-content-center">
                      <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="4500"><span class="sku-font">{{ productDetails.value.sku_code }}</span></div>
                    </div>
                    <div class="fw-bold fs-6 text-gray-400 text-center">{{ productConstants.PRODUCT_SKU }}</div>
                  </div>
                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div class="d-flex justify-content-center">
                      <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="4500">{{ formatPrice(productDetails.value.price) }}</div>
                    </div>
                    <div class="fw-bold fs-6 text-gray-400 text-center">{{ generalConstants.PRICE }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 fv-row mt-0">
        <div class="accordion" id="kt_accordion_1" v-if="productDetails.value">
          <!-- Size details -->
          <div class="accordion-item" v-if="productDetails.value.sizes">
            <h2 class="accordion-header" id="kt_accordion_1_header_1">
              <button
                class="accordion-button bg-white fs-4 fw-bold"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_1_body_1"
                aria-expanded="true"
                aria-controls="kt_accordion_1_body_1"
              >
                {{ productConstants.SIZE_DETAILS }}
              </button>
            </h2>
            <div
              id="kt_accordion_1_body_1"
              class="accordion-collapse collapse show"
              aria-labelledby="kt_accordion_1_header_1"
              data-bs-parent="#kt_accordion_1"
            >
              <div class="accordion-body">
                <Datatable
                  :table-data="productDetails.value.sizes"
                  :table-header="sizeTableHeader"
                  :enable-items-per-page-dropdown="true"
                  :rows-per-page="recordsPerPage"
                >
                  <template v-slot:cell-size="{ row: data }">
                    {{ data.size }}
                  </template>
                  <template v-slot:cell-height="{ row: data }">
                    {{ data.height }}
                  </template>
                  <template v-slot:cell-width="{ row: data }">
                    {{ data.width }}
                  </template>
                  <template v-slot:cell-sku_code="{ row: data }">
                    <span class="sku-font">{{ data.sku_code }}</span>
                  </template>
                  <template v-slot:cell-thickness="{ row: data }">
                    <span v-if="data.thickness">{{ data.thickness }}</span>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell-sort_order="{ row: data }">
                    {{ data.sort_order }}
                  </template>
                  <template v-slot:cell-is_default="{ row: data }">
                    {{ data.is_default }}
                  </template>
                  <template v-slot:cell-status="{ row: data }">
                    <span v-if="data.status == 1" class="badge badge-light-success">{{ generalConstants.ACTIVE }}</span>
                    <span v-if="data.status == 0" class="badge badge-light-danger">{{ generalConstants.INACTIVE }}</span>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
          <!-- Product SKU details -->
          <div class="accordion-item" v-if="productDetails.value.sku.length > 0">
            <h2 class="accordion-header" id="kt_accordion_1_header_2">
              <button
                class="accordion-button bg-white fs-4 fw-bold collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_1_body_2"
                aria-expanded="false"
                aria-controls="kt_accordion_1_body_2"
              >
                {{ productConstants.SKU_DETAILS }}
              </button>
            </h2>
            <div
              id="kt_accordion_1_body_2"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_1_header_2"
              data-bs-parent="#kt_accordion_1"
            >
              <div class="accordion-body">
                <Datatable
                  :table-data="productDetails.value.sku"
                  :table-header="skuTableHeader"
                  :enable-items-per-page-dropdown="true"
                  :rows-per-page="recordsPerPage"
                >
                  <template v-slot:cell-sku="{ row: data }">
                    <span class="sku-font me-1 attribute-name cursor-pointer" v-if="data.partnersku.length != 0" @click.prevent="showPartnerSKU(data.partnersku, data.sku)" data-bs-toggle="modal" data-bs-target="#trading_partner_modal">{{ data.modify_sku == '' ? data.sku : data.modify_sku }}</span>
                    <span class="sku-font me-1" v-else>{{ data.modify_sku == '' ? data.sku : data.modify_sku }}</span>
                    <span v-if="data.partnersku.length != 0" @click.prevent="showPartnerSKU(data.partnersku, data.sku)" data-bs-toggle="modal" data-bs-target="#trading_partner_modal" class="cursor-pointer badge rounded-pill bg-primary">{{ data.partnersku.length }}</span>
                  </template>
                  <template v-slot:cell-type="{ row: data }">
                    {{ data.setting_type }}
                  </template>
                  <template v-slot:cell-size="{ row: data }">
                    {{ data.get_size.size }}
                  </template>
                  <template v-slot:cell-options="{ row: data }">
                    <div v-if="data.options != ''">
                      <span class="fs-7 text-muted me-2 d-inline-block" v-for="(attribute, option, index) in data.options" :key="option">
                        {{ option }} <span class="text-primary">{{ attribute }}</span>
                        <span v-if="Object.keys(data.options).length - 1 != index">,</span>
                      </span>
                    </div>
                    <div v-else>-</div>
                  </template>
                  <template v-slot:cell-description="{ row: data }">
                    <template v-if="data.sku_description != null && data.sku_description.length > 100">
                      {{ data.sku_description != null ? data.sku_description.substring(0,100) : '-' }}
                      <el-tooltip v-if="data.sku_description.length > 100" class="box-item" effect="dark" :content="data.sku_description.match(/.{1,50}/g).join('<br/>')" raw-content placement="top"><i class="bi bi-three-dots text-primary"></i></el-tooltip>
                    </template>
                    <template v-else>
                      {{ data.sku_description != null ? data.sku_description : '-' }}
                    </template>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
          <!-- Options details -->
          <div class="accordion-item" v-if="productDetails.productAssignedOptions">
            <h2 class="accordion-header" id="kt_accordion_1_header_3">
              <button
                class="accordion-button bg-white fs-4 fw-bold collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_1_body_3"
                aria-expanded="false"
                aria-controls="kt_accordion_1_body_3"
              >
                {{ productConstants.OPTION_DETAILS }}
              </button>
            </h2>
            <div
              id="kt_accordion_1_body_3"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_1_header_3"
              data-bs-parent="#kt_accordion_1"
            >
              <div class="accordion-body">
                <div class="row">
                  <div class="col-md-6 mb-4" v-for="(item, index) in productDetails.productAssignedOptions" :key="index">
                    <div class="d-flex p-3 col-md-6 align-items-center bg-light rounded justify-content-between w-100"> 
                      <h5 class="mb-0 w-50">{{item['optionName']}} </h5>
                      <div class="w-75 text-end">
                        <span v-for="(attributeDetails, attribute_key) in item['attributes_details']" :key="attribute_key">
                          <span class="badge bg-success m-1">{{ attributeDetails }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Weight details -->
          <div class="accordion-item" v-if="productDetails.value.weight.length > 0">
            <h2 class="accordion-header" id="kt_accordion_1_header_4">
              <button
                class="accordion-button bg-white fs-4 fw-bold collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_1_body_4"
                aria-expanded="false"
                aria-controls="kt_accordion_1_body_4"
              >
                {{ productConstants.WEIGHT_DETAILS }}
              </button>
            </h2>
            <div
              id="kt_accordion_1_body_4"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_1_header_4"
              data-bs-parent="#kt_accordion_1"
            >
              <div class="accordion-body">
                <Datatable
                  :table-data="productDetails.value.weight"
                  :table-header="weightTableHeader"
                  :enable-items-per-page-dropdown="true"
                  :rows-per-page="recordsPerPage"
                >
                  <template v-slot:cell-setting_type="{ row: data }">
                    {{ data.setting_type }}
                  </template>
                  <template v-slot:cell-size="{ row: data }">
                    {{ data.size.size }}
                  </template>
                  <template v-slot:cell-options="{ row: data }">
                    <div v-if="data.options != ''">
                      <span class="fs-7 text-muted me-2 d-inline-block" v-for="(attribute, option, index) in data.options" :key="option">
                        {{ option }} <span class="text-primary">{{ attribute }}</span>
                        <span v-if="Object.keys(data.options).length - 1 != index">,</span>
                      </span>
                    </div>
                    <div v-else>-</div>
                  </template>
                  <template v-slot:cell-weight="{ row: data }">
                    {{ data.weight }}
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
          <!-- Shipping Package details -->
          <div class="accordion-item" v-if="shippingPackages.length > 0">
            <h2 class="accordion-header" id="kt_accordion_1_header_5">
              <button
                class="accordion-button bg-white fs-4 fw-bold collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_1_body_5"
                aria-expanded="false"
                aria-controls="kt_accordion_1_body_5"
              >
                {{ productConstants.SHIPPING_PACKAGES }}
              </button>
            </h2>
            <div
              id="kt_accordion_1_body_5"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_1_header_5"
              data-bs-parent="#kt_accordion_1"
            >
              <div class="accordion-body">
                <Datatable
                  :table-data="shippingPackages"
                  :table-header="packageTableHeader"
                  :enable-items-per-page-dropdown="true"
                  :rows-per-page="recordsPerPage"
                >
                  <template v-slot:cell-sku="{ row: data }">
                    <span class="sku-font">{{ data.sku }}</span>
                  </template>
                  <template v-slot:cell-internalPackage="{ row: data }">
                    <div v-if="data.internalPackage">
                      <el-tooltip class="box-item" effect="dark" :content="data.internalPackageDetails" placement="top" raw-content>
                        {{ data.internalPackage }}
                      </el-tooltip>
                    </div>
                    <div v-else>-</div>
                  </template>
                  <template v-slot:cell-externalPackage="{ row: data }">
                    <div v-if="data.externalPackage">
                      <el-tooltip class="box-item" effect="dark" :content="data.externalPackageDetails" placement="top" raw-content>
                        {{ data.externalPackage }}
                      </el-tooltip>
                    </div>
                    <div v-else>-</div>
                  </template>
                  <template v-slot:cell-status="{ row: data }">
                    <span v-if="data.status == 1" class="badge badge-light-success">{{ generalConstants.ACTIVE }}</span>
                    <span v-if="data.status == 0" class="badge badge-light-danger">{{ generalConstants.INACTIVE }}</span>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PartnerSkuModal
      ref="updateProps"
    ></PartnerSkuModal>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { formatText, formatPrice } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import useEventBus from '../../composable/useEventBus';
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import PartnerSkuModal from "@/components/modals/forms/PartnerSkuModal.vue";

export default {
  name: "product-detail",
  components: {
    Datatable,
    PartnerSkuModal
  },
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const route = useRoute();
    const router = useRouter();
    const productID = ref();
    const productDetails = reactive({});
    let assignedOptionsData = {};
    let shippingPackages = [];
    const loading = ref(false);
    const activePanel = ref('1');
    const generalConstants = globalConstant.general;
    const productConstants = globalConstant.product;
    const packageConstants = globalConstant.package;
    const orderConstants = globalConstant.orders;
    const updateProps = ref(null);
    const recordsPerPage = JSON.parse(window.localStorage.getItem('site_config'))['per_page'] ? Number(JSON.parse(window.localStorage.getItem('site_config'))['per_page']) : 10;

    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "back"){
        router.push('/products');
      }
    })

    //Size table header data
    const sizeTableHeader = ref([
      {
        name: generalConstants.TITLE,
        key: "size",
        sortable: false,
        width: "w-100px",
        alignment: 'text-center'
      },
      {
        name: generalConstants.HEIGHT,
        key: "height",
        sortable: true,
        width: "w-100px",
        alignment: 'text-center'
      },
      {
        name: generalConstants.WIDTH,
        key: "width",
        sortable: true,
        width: "w-100px",
        alignment: 'text-center'
      },
      {
        name: productConstants.SIZE_SKU,
        key: "sku_code",
        width: "w-150px",
        sortable: true,
        alignment: 'text-center'
      },
      {
        name: productConstants.THICKNESS,
        key: "thickness",
        width: "w-100px",
        sortable: false,
        alignment: 'text-center'
      },
      {
        name: generalConstants.SORTORDER,
        key: "sort_order",
        width: "w-100px",
        sortable: true,
        alignment: 'text-center'
      },
      {
        name: generalConstants.DEFAULT,
        key: "is_default",
        width: "w-100px",
        sortable: true,
        alignment: 'text-center'
      },
      {
        name: generalConstants.STATUS,
        key: "status",
        width: "w-100px",
        sortable: false,
        alignment: 'text-center'
      }
    ]);

    //SKU table header data
    const skuTableHeader = ref([
      {
        name: generalConstants.SKU,
        key: "sku",
        sortable: true,
        width: "w-80px",
        alignment: 'text-center'
      },
      {
        name: generalConstants.TYPE,
        key: "type",
        sortable: false,
        width: "w-100px",
        alignment: 'text-center'
      },
      {
        name: productConstants.SIZE,
        key: "size",
        sortable: false,
        width: "w-80px",
        alignment: 'text-center'
      },
      {
        name: productConstants.PRODUCT_OPTIONS,
        key: "options",
        width: "w-100px",
        sortable: false,
        alignment: 'text-center'
      },
      {
        name: generalConstants.DESCRIPTION,
        key: "description",
        width: "w-150px",
        sortable: false,
        alignment: 'text-center'
      }
    ]);

    //Weight table header data
    const weightTableHeader = ref([
      {
        name: generalConstants.TYPE,
        key: "setting_type",
        sortable: false,
        width: "w-200px",
        alignment: 'text-center'
      },
      {
        name: productConstants.SIZE,
        key: "size",
        sortable: false,
        width: "w-150px",
        alignment: 'text-center'
      },
      {
        name: productConstants.PRODUCT_OPTIONS,
        key: "options",
        width: "w-200px",
        sortable: false,
        alignment: 'text-center'
      },
      {
        name: orderConstants.WEIGHT,
        key: "weight",
        width: "w-150px",
        sortable: true,
        alignment: 'text-center'
      }
    ]);

    //Package table header data
    const packageTableHeader = ref([
      {
        name: generalConstants.SKU,
        key: "sku",
        sortable: false,
        width: "w-150px",
        alignment: 'text-center'
      },
      {
        name: packageConstants.INTERNAL_PACKAGE,
        key: "internalPackage",
        sortable: false,
        width: "w-150px",
        alignment: 'text-center'
      },
      {
        name: packageConstants.EXTERNAL_PACKAGE,
        key: "externalPackage",
        sortable: false,
        width: "w-150px",
        alignment: 'text-center'
      },
      {
        name: generalConstants.STATUS,
        key: "status",
        width: "w-150px",
        sortable: false,
        alignment: 'text-center'
      }
    ]);

    const getOptions = (options, attributes) => {
      let result = {};
      let optionArr = options.options.split(', ');
      let attributeArr = attributes.attributes.split(', ');
      if(optionArr.length > 0) {
        optionArr.map(function (value, index){
          result[value] = attributeArr[index];
        });
      }
      return result;
    }

    //Get Product details
    const getproductDetails = async() => {
      loading.value = true;
      await ApiService.query("products/" + productID.value)
      .then(({ data }) => {
        if(data.data){
          productDetails.value = data.data;
          if(data.data.additional_assign_data.length > 0){
            data.data.additional_assign_data.forEach((optionId) => {
                assignedOptionsData[optionId.master_option_id] = {};
                assignedOptionsData[optionId.master_option_id]['optionName'] = optionId.master_options.option_name;
                assignedOptionsData[optionId.master_option_id]['attributes_details'] = {};
                let additionalOptionAttributeDetails = [];
                optionId.additional_attributes.forEach((attr) => {
                  additionalOptionAttributeDetails.push(attr.assign_attribute.attribute_name)
                });
              assignedOptionsData[optionId.master_option_id]['attributes_details'] = additionalOptionAttributeDetails;
            });
          }
          if (Object.keys(assignedOptionsData).length > 0) {
            productDetails.productAssignedOptions = assignedOptionsData;
          }
          productDetails.value.sizes.forEach((obj) => {
            obj.is_default = (obj.is_default == 1) ? 'Yes' : 'No';
          });
          productDetails.value.weight.forEach((obj) => {
            obj.setting_type = (obj.setting_type == 0) ? 'For Size' : (obj.setting_type == 1 ? 'For Size With Additional Option' : 'Size with Additional Option Combination');
            obj.options = (obj.option_name != null && obj.option_name.options != null && obj.attribute_name != null  && obj.attribute_name.attributes != null) ? getOptions(obj.option_name, obj.attribute_name) : '';
          });
          productDetails.value.sku.forEach((obj) => {
            obj.setting_type = (obj.setting_type == 0) ? 'For Size' : (obj.setting_type == 1 ? 'For Size With Additional Option' : 'Size with Additional Option Combination');
            obj.options = (obj.option_name != null && obj.option_name.options != null && obj.attribute_name != null  && obj.attribute_name.attributes != null) ? getOptions(obj.option_name, obj.attribute_name) : '';
            obj.sku = (obj.modify_sku != '') ? obj.modify_sku : obj.sku;
            if(obj.shipping_package != null) {
              if(!Array.isArray(obj.shipping_package)) {
                obj.shipping_package = [obj.shipping_package];
              }
              obj.shipping_package.map(function (element) {
                element.sku = obj.sku;
                element.internalPackage = (element.internal_package != null) ? element.internal_package.package_code : '';
                element.internalPackageDetails = (element.internal_package != null) ? 'Dimensions: ' + element.internal_package.package_length + 'L x ' + element.internal_package.package_width + 'W x ' + element.internal_package.package_height + 'H (' + element.internal_package.length_class + ')<br> Max Weight: ' + element.internal_package.max_weight + ' ' + element.internal_package.weight_class + '<br> Box Weight: ' + element.internal_package.box_weight + ' ' + element.internal_package.weight_class : '';
                element.externalPackage = (element.external_package != null) ? element.external_package.package_code : '';
                element.externalPackageDetails = (element.external_package != null) ? 'Dimensions: ' + element.external_package.package_length + 'L x ' + element.external_package.package_width + 'W x ' + element.external_package.package_height + 'H (' + element.external_package.length_class + ')<br> Max Weight: ' + element.external_package.max_weight + ' ' + element.external_package.weight_class + '<br> Box Weight: ' + element.external_package.box_weight + ' ' + element.external_package.weight_class : '';
                shippingPackages.push(element);
              });            
            }
          });
        }
        loading.value = false;
      })
      .catch((error) => {
        loading.value = false;
        let response = error.response;
        let status = response.status;
        let message='';
        if (typeof (error.response) !== 'undefined') {
          message = error.response.data.message;
        } else if (typeof (error.message) !== 'undefined') {
          message = error.message;
        } else {
          message = generalConstants.NOTIFY_WRONG_MESSAGE;
        }
        notificationFire(message, generalConstants.NOTIFY_ERROR);
        if (status == 404 || status == 422) {
          router.push({ name: 'products'})
        }
      });
    }
    //Get Partner SKU details
    const showPartnerSKU = (partnerData, sku) => {
      updateProps.value.updateProps(partnerData, sku);
    }
    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name": generalConstants.BACK , 'action': 'back','class': 'btn-primary','isLoader':false, 'iconClass':"bi bi-arrow-left fs-4" }]);
    }
    onMounted(() => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": "Products", "path": "products"}, { name: formatText(route.name), active: true }]);
      productID.value = route.params.id;
      getproductDetails();
      generateHeaderToolbarButtons();
    });

    return {
      productID,
      productDetails,
      assignedOptionsData,
      loading,
      activePanel,
      shippingPackages,
      generalConstants,
      productConstants,
      packageConstants,
      orderConstants,
      formatPrice,
      sizeTableHeader,
      weightTableHeader,
      packageTableHeader,
      skuTableHeader,
      showPartnerSKU,
      updateProps,
      recordsPerPage
    };
  },
};

</script>
