<template>
  <div class="modal fade" tabindex="-1" id="trading_partner_modal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">
            {{ partnerConstants.PARTNER_SKU_DATA }} (<span class="sku-font">{{ partnerSku }}</span
            >)
          </h2>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
            <Datatable
              :table-data="tableData"
              :table-header="tableHeader"
              :enable-items-per-page-dropdown="true"
              :rows-per-page="recordsPerPage"
              ref="updateTableProps"
            >
              <template v-slot:cell-id="{ row: data }">
                {{ data }}
              </template>
              <template v-slot:cell-platform="{ row: data }">
                {{ data.partner.get_platform.platform_name }}
              </template>
              <template v-slot:cell-sku="{ row: data }">
                <span class="sku-font">{{ data.partner_sku }}</span>
              </template>
              <template v-slot:cell-description="{ row: data }">
                <template
                  v-if="
                    data.partner_sku_description != null &&
                    data.partner_sku_description.length > 100
                  "
                >
                  {{
                    data.partner_sku_description != null
                      ? data.partner_sku_description.substring(0, 100)
                      : "-"
                  }}
                  <el-tooltip
                    v-if="data.partner_sku_description.length > 100"
                    class="box-item"
                    effect="dark"
                    :content="
                      data.partner_sku_description
                        .match(/.{1,50}/g)
                        .join('<br/>')
                    "
                    raw-content
                    placement="top"
                    ><i class="bi bi-three-dots text-primary"></i
                  ></el-tooltip>
                </template>
                <template v-else>
                  {{
                    data.partner_sku_description != null
                      ? data.partner_sku_description
                      : "-"
                  }}
                </template>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import globalConstant from "@/core/data/globalConstant.js";

export default {
  name: "partner-sku-modal",
  components: {
    Datatable,
  },
  props: {},
  setup() {
    const generalConstants = globalConstant.general;
    const orderConstants = globalConstant.orders;
    const partnerConstants = globalConstant.partnerSku;
    const partnerSku = ref("");
    const tableData = ref([]);
    const updateTableProps = ref(null);
    const recordsPerPage = JSON.parse(window.localStorage.getItem('site_config'))['per_page'] ? Number(JSON.parse(window.localStorage.getItem('site_config'))['per_page']) : 10;
    const updateProps = (data, sku) => {
      tableData.value = data;
      partnerSku.value = sku;
      updateTableProps.value.updateProps(data.length, recordsPerPage, 1);
    };

    //table header data
    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: true,
        width: "w-10px",
        alignment: "text-center",
      },
      {
        name: orderConstants.PLATFORM,
        key: "platform",
        sortable: true,
        width: "w-100px",
        alignment: "text-center",
      },
      {
        name: orderConstants.PARTNER,
        key: "sku",
        sortable: true,
        width: "w-100px",
        alignment: "text-center",
      },
      {
        name: partnerConstants.PARTNER_SKU_DESCRIPTION,
        key: "description",
        width: "w-150px",
        sortable: false,
        alignment: "text-center",
      },
    ]);

    onMounted(() => {});

    return {
      generalConstants,
      tableHeader,
      updateProps,
      tableData,
      partnerSku,
      updateTableProps,
      partnerConstants,
      recordsPerPage
    };
  },
};
</script>